/* eslint-disable */
import React from "react";
import Footer from "../Components/footer";
import Navbar from "../Components/navbar";
import SignUp from "../Components/Onboard/signup";

const Onboarding = () =>{
    return(
        <>
            {/* <Navbar /> */}
            <SignUp />
            {/* <Footer /> */}
        </>
    )
}

export default Onboarding;