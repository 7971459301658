import React from "react";

const HeaderImg3 = () =>{
    return(
        <>
            <div data-aos="fade-left" data-aos-duration="3000" className="header-img" style={{flex: "1"}} >
                {/* <img src="/images/Man.jpg" width="100%" height="60%" alt="High quality House Cliparts For Free!" /> */}
            </div>
        </>
    )
}
export default HeaderImg3;