import React from "react";
import SignIn from "../Components/Onboard/signin";

const SignInPage = () => {
  return (
    <>
      <SignIn />
    </>
  );
};

export default SignInPage;
